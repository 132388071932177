import './NotFound.css';
import '../../App.css';

function NotFound() {
  return (
    <div className="error">
        <h1>Sorry, this page doesn't exist</h1>
        <a href="../">Go back home</a>
    </div>
  );
}

export default NotFound;