import './Contact.css';
import '../../App.css';
import me from '../../images/sunil.png';

function Contact() {

  setTimeout(() => {
    if (sessionStorage.getItem("sent") === '1') {
      document.getElementById("zone").innerText = "";
      document.getElementById("ligne").textContent = "";
      document.getElementById("reponse").textContent = "Message successfully sent ! I will answer you as soon as possible.";
      document.getElementById("aaa").style.display = "block";
    }
  }, 10)

  var Airtable = require('airtable');
  var base = new Airtable({ apiKey: process.env.REACT_APP_API }).base('appoRHQpa09WemKF5');

  function createArticle() {
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var object = document.getElementById("object").value;
    var message = document.getElementById("message").value;

    base('website').create([
      {
        "fields": {
          "name": name,
          "email": email,
          "object": object,
          "message": message,
        }
      }
    ], function (err) {
      if (err) {
        console.error(err);
        return;
      }
    });

    document.getElementById("zone").innerText = "";
    document.getElementById("ligne").textContent = "";
    document.getElementById("reponse").textContent = "Message sent successfully ! You will be redirected to the home page in 5 seconds";

    sessionStorage.setItem("sent", '1');

    window.scrollTo(0, 0);

    setTimeout(() => {
      window.location.href = '../';
    }, 5000)

  }

  return (
    <div className="page">
      <div className='me'>
        <img src={me} alt="sunil"></img>
      </div>
      <div className="social-btns">
        <a href="https://www.twitter.com/snydrive" target="_blank" rel='noreferrer'><i className="fa fa-twitter"></i></a>
        <a href="https://www.linkedin.com/in/sunil-goulamhoussen/" target="_blank" rel='noreferrer'><i className="fa fa-linkedin"></i></a>
        <a href="mailto:sunil.goulamhoussen@edu.devinci.fr" target="_blank" rel='noreferrer'><i className="fa fa-envelope"></i></a>
      </div>
      <div className='contact'>
        <p id="reponse">Let's get in touch !</p>
        <p id="ligne">Feel free to send me a little message, I'll answer quickly !</p>
        <p id="aaa">If I don't, feel free to send me an email at <a href="mailto:sunil.goulamhoussen@edu.devinci.fr" target="_blank" rel="noreferrer">sunil.goulamhoussen@edu.devinci.fr</a></p>
        <div className='form' id="zone">
          <p>
            <input id="name"></input>
            <label>Name</label>
          </p>
          <p>
            <input id="email" type="email"></input>
            <label>E-mail</label>
          </p>
          <p>
            <input id="object"></input>
            <label>Object</label>
          </p>
          <p>
            <textarea id="message"></textarea>
            <label>Message</label>
          </p>
          <button className='form-btn' onClick={() => createArticle()}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;