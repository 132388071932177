import './Resume.css';
import '../../App.css';
import qr from '../../images/snydrive.svg';
import resume from '../../documents/resume_sunil-goulamhoussen_6months-internship.pdf';

function Resume() {

    var a = 0;
    var b = 0;
    var c = 0;
    var d = 0;
    var e = 0;
    var f = 0;
    var g = 0;

    const currentDate = new Date();
    const targetDate = new Date('March 1, 2024');
    const diffTime = Math.abs(targetDate - currentDate);
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));

    function openit(divi) {
        switch (divi) {
            case 'a':
                if (a === 0) {
                    document.getElementById('1').style.fontSize = '1.25em';
                    document.getElementById('1').style.marginTop = '0vh';
                    document.getElementById('1').style.opacity = '1';
                    a = 1;
                }
                else {
                    document.getElementById('1').style.fontSize = '0vh';
                    document.getElementById('1').style.marginTop = '-5vh';
                    document.getElementById('1').style.opacity = '0';
                    a = 0;
                }
                break;
            case 'b':
                if (b === 0) {
                    document.getElementById('2').style.fontSize = '1.25em';
                    document.getElementById('2').style.marginTop = '0vh';
                    document.getElementById('2').style.opacity = '1';
                    b = 1;
                }
                else {
                    document.getElementById('2').style.fontSize = '0vh';
                    document.getElementById('2').style.marginTop = '-5vh';
                    document.getElementById('2').style.opacity = '0';
                    b = 0;
                }
                break;
            case 'c':
                if (c === 0) {
                    document.getElementById('3').style.fontSize = '1.25em';
                    document.getElementById('3').style.marginTop = '0vh';
                    document.getElementById('3').style.opacity = '1';
                    c = 1;
                }
                else {
                    document.getElementById('3').style.fontSize = '0vh';
                    document.getElementById('3').style.marginTop = '-5vh';
                    document.getElementById('3').style.opacity = '0';
                    c = 0;
                }
                break;
            case 'd':
                if (d === 0) {
                    document.getElementById('4').style.fontSize = '1.25em';
                    document.getElementById('4').style.marginTop = '0vh';
                    document.getElementById('4').style.opacity = '1';
                    d = 1;
                }
                else {
                    document.getElementById('4').style.fontSize = '0vh';
                    document.getElementById('4').style.marginTop = '-5vh';
                    document.getElementById('4').style.opacity = '0';
                    d = 0;
                }
                break;
            case 'e':
                if (e === 0) {
                    document.getElementById('5').style.fontSize = '1.25em';
                    document.getElementById('5').style.marginTop = '0vh';
                    document.getElementById('5').style.opacity = '1';
                    e = 1;
                }
                else {
                    document.getElementById('5').style.fontSize = '0vh';
                    document.getElementById('5').style.marginTop = '-5vh';
                    document.getElementById('5').style.opacity = '0';
                    e = 0;
                }
                break;
            case 'f':
                if (f === 0) {
                    document.getElementById('6').style.fontSize = '1.25em';
                    document.getElementById('6').style.marginTop = '0vh';
                    document.getElementById('6').style.opacity = '1';
                    f = 1;
                }
                else {
                    document.getElementById('6').style.fontSize = '0vh';
                    document.getElementById('6').style.marginTop = '-5vh';
                    document.getElementById('6').style.opacity = '0';
                    f = 0;
                }
                break;
            case 'g':
                if (g === 0) {
                    document.getElementById('7').style.fontSize = '1.25em';
                    document.getElementById('7').style.marginTop = '0vh';
                    document.getElementById('7').style.opacity = '1';
                    g = 1;
                }
                else {
                    document.getElementById('7').style.fontSize = '0vh';
                    document.getElementById('7').style.marginTop = '-5vh';
                    document.getElementById('7').style.opacity = '0';
                    g = 0;
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="page">
            <h1>My Resume</h1>
            <a href={resume} download={resume} className='resume-a'><button className='resume-btn'>Download my resume</button></a>
            <div className="resume">
                <div className="doc">
                    <h2>Sunil GOULAMHOUSSEN</h2>
                    <div className="cv-info">
                        <p className="cv-txt">
                            <a href="mailto:sunil.goulamhoussen@edu.devinci.fr" target="_blank" rel="noreferrer" className="cv-link">sunilgouvi@gmail.com</a>
                            <span>Engineering student at ESILV Paris - La Défense</span>
                            <span>Actively seeking an International 6-months internship in IT development / Consulting - February 2024 to August 2024</span>
                        </p>
                    </div>
                    <p id="cv-title" onClick={() => openit('a')}>
                        <span className="cv-span">Professional and associative experiences</span>
                    </p>
                    <div className="cv-drop mt-mb-9" id="1">
                        <p>
                            <span className="cv-job">Deloitte Luxembourg</span> - Cloud Engineering Analyst
                            <span className="cv-date">2024 - {diffMonths} months</span>

                        </p>

                        <p className="cv-description">
                            Cloud Engineering Analyst working on Cloud transformation projects for Luxembourgish clients. Currently working with Azure, Snowflake, Python and SQL.
                        </p>

                        <p>
                            <span className="cv-job">Sny Technologies</span> - Blockchain and Web3 development agency
                            <span className="cv-date">2023 - 2 months</span>
                        </p>

                        <p className="cv-description">
                            Freelance Web3 Developer: Web3 Development (Full Stack) with React JS, Node JS, Rust, Solidity, MySQL / MongoDB & Smart contract development and integration + Mobile development with React Native
                        </p>

                        <p>
                            <span className="cv-job">Horae</span> - Blockchain Startup creating certificates of authenticity for luxury watches
                            <span className="cv-date">2023 - 6 months</span>
                        </p>

                        <p className="cv-description">
                            Web3 Developer: Web3 Development with React JS and Node JS & Mobile development with React Native + Smart contract integration
                        </p>

                        <p>
                            <span className="cv-job">Kryptosphere</span> - Blockchain association
                            <span className="cv-date">2022 - Today - 1 year and 2 months</span>
                        </p>

                        <p className="cv-description">
                            Member of the development department
                        </p>

                        <div>
                            <span className="cv-job">Vinci Eco Drive</span> - Electric Automotive association
                            <span className="cv-date">2020 - Today - 3 years and 4 months</span>
                            <p className="cv-description">
                                <span>Communication Team Member - Website Manager, IT Transformation, SEO</span>
                                <span>General Secretary - Team Management, Legal Documents, Leadership</span>
                                <span>Formula Student Project Member - Modelling the vehicle dynamics of the Formula Student on MATLAB & Simulink</span>
                            </p>
                        </div>

                        <div>
                            <span className="cv-job">La Plume De Vinci</span> - Reading, writing and journalism association
                            <span className="cv-date">2020 - 2022 - 2 years</span>
                            <p className="cv-description">
                                <span>IT Manager - Website creation</span>
                                <span>Treasurer - Budget management</span>
                            </p>
                        </div>

                        <div>
                            <span className="cv-job">Noces Italiennes</span> - Internship - IT Project Manager
                            <span className="cv-date">2021 - 3 months</span>
                            <p className="cv-description">
                                <span>Management and maintenance of the showcase site</span>
                                <span>Development of a document automation module for customers with Javascript and Vue.js</span>
                            </p>
                        </div>
                    </div>

                    <p id="cv-title" onClick={() => openit('b')}>
                        <span className="cv-span">Education</span>
                    </p>
                    <div className="cv-drop mt-mb-9" id="2">
                        <p>
                            <span className="cv-job">ESILV</span> - Engineering school
                            <span className="cv-date">2019 - 2024 - 5 years</span>
                        </p>

                        <p className="cv-description">
                            <span>Generalist engineer at the heart of digital technologies</span>
                            <span>Fintech specialisation</span>
                        </p>

                        <p>
                            <span className="cv-job">EMLV</span> - Management school
                            <span className="cv-date">2023 - 2024 - 4 Months</span>
                        </p>

                        <p className="cv-description">
                            <span>Business engineering Formation</span>
                            <span>
                                Communication - Sales - Negotiation - Key Account Managing - Marketing BtoB - Leadership
                            </span>
                        </p>

                        <span className="cv-job">Soft Skills courses</span>
                        <span className="cv-date">2019 - 2024 - 5 years</span>

                        <p className="cv-description">
                            <span>Creativity, Agility and Innovation - Learning to learn - Improving efficiency</span>
                            <span>How to sell your ideas - Human relations in team cooperation - Design Thinking</span>
                            <span>Self confidence - Stress management - MBTI training - Economics of Happiness</span>
                            <span>Leadership - Marketing yourself</span>
                        </p>

                        <p>
                            <span className="cv-job">Paul Claudel d'Hulst</span> - High School
                            <span className="cv-date">2016 - 2019 - 3 years</span>
                        </p>

                        <p className="cv-description">
                            <span className="cv-description">Scientific Baccalaureate with Honours in Computer and Digital Sciences speciality</span>
                        </p>
                    </div>

                    <p id="cv-title" onClick={() => openit('g')}>
                        <span className="cv-span">Some other projects</span>
                    </p>
                    <div className="cv-drop mt-mb-9" id="7">
                        <span className="cv-job">JavaScript</span>

                        <p className="cv-description">
                            <span>- Website in ReactJS for articles management</span>
                            <span>- ERC721 smart contract interactions with ReactJS</span>
                            <span>- Car identity management with token exchanges and wallet modification with ReactJS and EthersJS</span>
                        </p>

                        <span className="cv-job">C#</span>

                        <p className="cv-description">
                            <span>- Covid propagation modelling in 5 countries</span>
                            <span>- Image processing software with WPF interface</span>
                            <span>- Bike shop management software with SQL database and WPF interface</span>
                        </p>

                        <span className="cv-job">Python</span>

                        <p className="cv-description">
                            <span>- Data analysis in Python on a Django site processing land value requests in France in 2020 and 2021</span>
                            <span>- Creation of a Django site with a database of vehicles sold in France in 2021</span>
                        </p>

                        <span className="cv-job">PHP</span>

                        <p className="cv-description">
                            <span>- Social Media Website with PHP backend - User management / publication management</span>
                        </p>

                    </div>

                    <p id="cv-title" onClick={() => openit('c')}>
                        <span className="cv-span">Softwares</span>
                    </p>

                    <p className="cv-drop mt-mb-9 flexone" id="3">
                        <span className="cv-description cv-list">
                            Office Suite
                        </span>
                        <span className="cv-description cv-list">
                            Adobe Suite
                        </span>
                        <span className="cv-description cv-list">
                            Visual Studio Code
                        </span>
                        <span className="cv-description cv-list">
                            MATLAB
                        </span>
                        <span className="cv-description cv-list">
                            Dataiku
                        </span>
                        <span className="cv-description cv-list">
                            Python
                        </span>
                        <span className="cv-description cv-list">
                            Git & GitHub
                        </span>
                        <span className="cv-description cv-list">
                            SolidWorks
                        </span>
                        <span className="cv-description cv-list">
                            Electrum
                        </span>
                    </p>

                    <p id="cv-title" onClick={() => openit('d')}>
                        <span className="cv-span">Computer languages</span>
                    </p>
                    <p className="cv-drop mt-mb-9 flexone" id="4">
                        <span className="cv-description cv-list">
                            C/C++/C#
                        </span>
                        <span className="cv-description cv-list">
                            Rust
                        </span>
                        <span className="cv-description cv-list">
                            Solidity
                        </span>
                        <span className="cv-description cv-list">
                            CSS
                        </span>
                        <span className="cv-description cv-list">
                            HTML
                        </span>
                        <span className="cv-description cv-list">
                            JavaScript
                        </span>
                        <span className="cv-description cv-list">
                            Matlab
                        </span>
                        <span className="cv-description cv-list">
                            Python
                        </span>
                        <span className="cv-description cv-list">
                            R
                        </span>
                        <span className="cv-description cv-list">
                            SQL
                        </span>
                        <span className="cv-description cv-list">
                            Linux
                        </span>
                    </p>

                    <p id="cv-title" onClick={() => openit('e')}>
                        <span className="cv-span">Languages</span>
                    </p>
                    <p className="cv-drop mt-mb-9 flexone" id="5">
                        <span className="cv-description cv-list">
                            French (native)
                        </span>
                        <span className="cv-description cv-list">
                            English (B2 TOEFL ITP)
                        </span>
                        <span className="cv-description cv-list">
                            Spanish (academic level)
                        </span>
                        <span className="cv-description cv-list">
                            Hindi (beginner)
                        </span>
                    </p>

                    <p id="cv-title" onClick={() => openit('f')}>
                        <span className="cv-span">Hobbies & Interests</span>
                    </p>
                    <p className="cv-drop mt-mb-9 flexone" id="6">
                        <span className="cv-description cv-list">
                            Cloud Technologies
                        </span>
                        <span className="cv-description cv-list">
                            Website Creation
                        </span>
                        <span className="cv-description cv-list">
                            Blockchain Technology
                        </span>
                        <span className="cv-description cv-list">
                            Financial Technologies
                        </span>
                        <span className="cv-description cv-list">
                            Car news and ecosystem
                        </span>
                    </p>
                </div>
            </div>
            <div className='qrcode'>
                <a href='https://linktr.ee/snydrive' target="_blank" rel="noreferrer"><img src={qr} alt="qr_code_sunil"></img></a>
                <a href='https://linktr.ee/snydrive' target="_blank" rel="noreferrer">https://linktr.ee/snydrive</a>
            </div>
        </div >
    );
}

export default Resume;