import "./Footer.css";

function Footer() {

    return (
        <div id="Footer">
            <span>Contact me at</span>
            <a href="mailto:sunil.goulamhoussen@edu.devinci.fr" target="_blank" rel="noreferrer">sunil.goulamhoussen@edu.devinci.fr</a>
            <a href="https://linkedin.com/in/sunilgoulamhoussen" target="_blank" rel="noreferrer">LinkedIn</a>
            <a href="https://www.twitter.com/snydrive" target="_blank" rel="noreferrer">Twitter</a>
            <p></p>            
            <span>My GitHub - SnyDrive25</span>
            <a href="https://github.com/snyDrive25" target="_blank" rel="noreferrer">https://github.com/snyDrive25</a>
            <span>Vinci Eco Drive</span>
            <a href="https://vinciecodrive.fr" target="_blank" rel="noreferrer">https://vinciecodrive.fr</a>
            <span>La Plume De Vinci</span>
            <a href="https://laplumedevinci.fr" target="_blank" rel="noreferrer">https://laplumedevinci.fr</a>
            <span>ESILV</span>
            <a href="https://esilv.fr" target="_blank" rel="noreferrer">https://esilv.fr</a>
            <span>Supervised personal work (2017) : The hydrogen car</span>
            <a href="https://voiturehydrogene830021783.wordpress.com" target="_blank" rel="noreferrer">https://voiturehydrogene830021783.wordpress.com</a>
        </div>
    );
}

export default Footer;