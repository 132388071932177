import './Home.css';

function Home() {
  return (
    <div className="App">
      <div className='content'>
        <h1>
          Welcome here_
        </h1>
        <h2>
          I'm Sunil G.
        </h2>
        <p>
          <span>A Car & Tech</span>
          <span>Enthusiast</span>
        </p>
        <p className="description">
          <span>
            My name is Sunil GOULAMHOUSSEN, I'm 23, and I currently work as a Cloud Engineering Analyst at Deloitte Luxembourg. I recently graduated from an engineering school near La Défense, Paris, where I specialized in Fintech. I'm passionate about web technologies and especially the evolution of the web into a more respectful, decentralized, and interactive space, such as Web3.
          </span>
          <span className="notel">
            I aspire to make our digital world a better place by addressing the challenges of our most widespread activity—surfing the web. While it has revolutionized our way of living and social interactions, it still poses issues like dependency, overconsumption, and the ever-growing concern of personal data theft.
          </span>
          <span>
            Feel free to check out my <a href="../studies">studies</a> or my <a href="../resume">resume</a> to learn more about my background and experience.
          </span>
          <span>
            If you have any questions, don't hesitate to contact me via the <a href="../contact">contact page</a>!
          </span>
        </p>

      </div>
      <div className='cards'>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Cloud Engineering
              <a href='https://www.deloitte.com/lu/en.html' target="_blank" rel="noreferrer">Deloitte Luxembourg</a>
              <div className='lang'>
                <span>Microsoft Azure</span>
                <span>Python</span>
                <span>SQL</span>
                <span>Dataiku</span>
                <span>Snowflake</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              I'm working as a Cloud Engineering Analyst at Deloitte Luxembourg. I'm working on Cloud projects for Luxembourgish clients.
              <br></br>
              <span>Microsoft Azure</span>
              <span>Python</span>
              <span>SQL</span>
              <span>Dataiku</span>
              <span>Snowflake</span>
              <a href="https://www.deloitte.com/lu/en.html" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              I'm working as a Cloud Engineering Analyst at Deloitte Luxembourg. I'm working on Cloud projects for Luxembourgish clients.
              <a href="https://www.deloitte.com/lu/en.html" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Web3 Hackathon
              <a href='https://www.starton.io/' target="_blank" rel="noreferrer">ETH Warsaw</a>
              <div className='lang'>
                <span>React</span>
                <span>Web3Auth</span>
                <span>API</span>
                <span>Synaps KYB</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              We created with my team a platform that secures companies' invoices with blockchain. 3rd prize of Request Network Sponsor.
              <br></br>
              <span>React</span>
              <span>Web3Auth</span>
              <span>API</span>
              <span>Synaps KYB</span>
              {/* <a href="https://eth-warsaw2.vercel.app/" target="_blank" rel="noreferrer">
                Browse website
              </a> */}
              <a href="https://web.sunil.fr/invoic3/" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              We created with my team a platform that secures companies' invoices with blockchain. 3rd prize of Request Network Sponsor.
              {/* <a href="https://eth-warsaw2.vercel.app/" target="_blank" rel="noreferrer">
                Browse website
              </a> */}
              <a href="https://web.sunil.fr/invoic3/" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          {/* <object type="image/svg+xml" aria-label="idcar" data="https://gh-card.dev/repos/lucas-kacz/eth_warsaw2.svg?link_target=_blank"></object> */}
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Web3 internship
              <a href='https://www.horae.io/' target="_blank" rel="noreferrer">Horae</a>
              <div className='lang'>
                <span>React</span>
                <span>wagmi</span>
                <span>ethers.js</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              Watch certification through blockchain for users and brands.
              <br></br>
              <span>React</span>
              <span>wagmi</span>
              <span>ethers.js</span>
              <a href="https://www.horae.io" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              Watch certification through blockchain for users and brands.
              <a href="https://www.horae.io" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Web3 Hackathon
              <a href='https://www.starton.io/' target="_blank" rel="noreferrer">Starton</a>
              <div className='lang'>
                <span>ReactJS</span>
                <span>ethers.js</span>
                <span>Starton API</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              We created with my team a website that can create and manage a numerical passport for cars so that there is no more possibility to falsify the odometer or other car data.
              <br></br>
              <span>React</span>
              <span>web3.js</span>
              <a href="https://idcar.lesminions.com" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              We created with my team a website that can create and manage a numerical passport for cars so that there is no more possibility to falsify the odometer or other car data.
              <a href="https://idcar.lesminions.com" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          {/* <object type="image/svg+xml" aria-label="idcar" data="https://gh-card.dev/repos/SnyDrive25/IdCar.svg?link_target=_blank"></object> */}
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Website creation
              <a href="https://laplumedevinci.fr" target="_blank" rel="noreferrer">La Plume De Vinci</a>
              <div className='lang'>
                <span>ReactJS</span>
                <span>Airtable API</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              I created a dynamic website with database integration and admin website. It is used to produce and show articles and writtings of the association members.
              <br></br>
              <span>ReactJS</span>
              <span>Airtable API</span>
              <a href="https://laplumedevinci.fr" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              I created a dynamic website with database integration and admin website. It is used to produce and show articles and writtings of the association members.
              <a href="https://laplumedevinci.fr" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          {/* <object type="image/svg+xml" aria-label="laplumedevinci" data="https://gh-card.dev/repos/SnyDrive25/LaPlumeDeVinci.svg?link_target=_blank"></object> */}
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Social Media Website
              <a href='https://esilv.fr' target="_blank" rel="noreferrer">ESILV</a>
              <div className='lang'>
                <span>ReactJS</span>
                <span>PHP Backend</span>
                <span>SQL Database</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              We created with my team a social media website that allows publication creation, comments, login and register pages.
              <br></br>
              <span>React</span>
              <span>PHP Backend</span>
              <span>SQL Database</span>
              <a href="https://shareit.lesminions.com" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              We created with my team a social media website that allows publication creation, comments, login and register pages.
              <a href="https://shareit.lesminions.com" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          {/* <object type="image/svg+xml" aria-label="shareit" data="https://gh-card.dev/repos/SnyDrive25/WebDev_Project.svg?link_target=_blank"></object> */}
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Website SEO management
              <a href="https://new.vinciecodrive.fr" target="_blank" rel="noreferrer">Vinci Eco Drive</a>
              <div className='lang'>
                <span>SEO</span>
                <span>HTML</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              I participated at the website transition and managed the SEO ranking of the new website.
              <br></br>
              <span>React</span>
              <span>web3.js</span>
              <a href="https://new.vinciecodrive.fr" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              I participated at the website transition and managed the SEO ranking of the new website.
              <a href="https://new.vinciecodrive.fr" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              ERC721 contract usage
              <a href='https://esilv.fr' target="_blank" rel="noreferrer">ESILV</a>
              <div className='lang'>
                <span>ReactJS</span>
                <span>web3.js</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              I created an UX interface to interact with ERC721 smart contracts with fake collections on Sepolia Testnet
              <br></br>
              <span>React</span>
              <span>web3.js</span>
              <a href="https://ux.lesminions.com" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              I created an UX interface to interact with ERC721 smart contracts with fake collections on Sepolia Testnet
              <a href="https://ux.lesminions.com" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          {/* <object type="image/svg+xml" aria-label="ux-erc721" data="https://gh-card.dev/repos/SnyDrive25/web3connect.svg?link_target=_blank"></object> */}
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Blockchain Development
              <a href="https://kryptosphere.org" target="_blank" rel="noreferrer">Kryptosphere</a>
              <div className='lang'>
                <span>Solidity</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              I'm improving my blockchain development skills by practicing with others in solidity, listening to workshops and many other activities. Thanks to Kryptosphere for all this support.
              <br></br>
              <span>React</span>
              <span>web3.js</span>
              <a href="https://kryptosphere.org" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              I'm improving my blockchain development skills by practicing with others in Solidity, listening to workshops and many other activities. Thanks to Kryptosphere for all this support.
              <a href="https://kryptosphere.org" target="_blank" rel="noreferrer">
                Browse website
              </a>
            </p>
          </div>
        </div>
        <div className='card'>
          <div className='past'>
            <p className='title'>
              Web3 Buisness Hackathon
              <a href='https://iex.ec' target="_blank" rel="noreferrer">iExec</a>
              <div className='lang'>
                <span>Culture Web3</span>
                <span>Project Managment</span>
              </div>
            </p>
            <p className='mobile-ctn'>
              With one teammate we established a Buisness model to develop fictively a NFT market place with visited monuments certification for example.
              <br></br>
              <span>Culture Web3</span>
              <span>Project Managment</span>
            </p>
          </div>
          <div className='future'>
            <p className='web-ctn'>
              With one teammate we established a Buisness model to develop fictively a NFT market place with visited monuments certification for example.
            </p>
          </div>
        </div>
      </div>
      <article class="user">
        <dl class="user-data">
          <dt>Avatar:</dt>
          <dd class="user-avatar">
            <img src="https://avatars.githubusercontent.com/u/74963340?v=4" alt="github_profile" />
          </dd>
          <dt>Fullname:</dt>
          <dd class="user-name"></dd>
          <dt>Account:</dt>
          <a class="user-github-url" href="https://github.com/SnyDrive25"><dd class="user-account">SnyDrive25</dd></a>
        </dl>
        <dl class="user-stats">
          <dt>Repos</dt>
          <a class="user-repos-url" href="https://github.com/SnyDrive25?tab=repositories"><dd class="user-repos" data-stats="repos">17</dd></a>
        </dl>
      </article>
    </div>
  );
}

export default Home;
