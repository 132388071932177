import { useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import me from '../../images/logo.png';

function Navbar() {

    const [nav, setNav] = useState(false);
    const location = useLocation();

    function biggerNav() {
        if (nav) {
            setNav(false);
            document.getElementById("scrolled").style.display = "none";
            document.getElementById("cross").style.display = "none";
            document.getElementById("hamburger").style.display = "block";
            document.getElementById("Navbar").style.height = "10vh";
        }
        else {
            setNav(true);
            document.getElementById("Navbar").style.height = "60vh";
            document.getElementById("hamburger").style.display = "none";
            document.getElementById("cross").style.display = "block";
            setTimeout(() => {
                document.getElementById("scrolled").style.display = "grid";
            }, 400);
        }
    }

    function handleClick() {
        if (window.innerWidth < 700) {
            biggerNav();
        }
    }

    if (window.innerWidth > 700) {
        var prevScrollpos = window.pageYOffset;
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("Navbar").style.top = "0";
                document.getElementById("Navbar").style.left = "0vw";
                document.getElementById("Navbar").style.width = "100vw";
                document.getElementById("Navbar").style.transform = "scale(100%)";
            } else {
                document.getElementById("Navbar").style.top = "-1.5vh";
                document.getElementById("Navbar").style.left = "-50vw";
                document.getElementById("Navbar").style.width = "200vw";
                document.getElementById("Navbar").style.transform = "scale(75%)";
            }
            prevScrollpos = currentScrollPos;
        }
    }

    return (
        <div id="Navbar">
            <div className="logo">
                <Link to="/" onClick={handleClick}><img src={me} alt="sunil"></img></Link>
            </div>
            <div className="titles">
                <Link to="/" id="home" className={location.pathname === "/" ? "nav-active" : ""} onClick={handleClick}>Home</Link>
                <Link to="/studies" id="studies" className={location.pathname === "/studies" ? "nav-active" : ""} onClick={handleClick}>Studies</Link>
                <Link to="/resume" id="resume" className={location.pathname === "/resume" ? "nav-active" : ""} onClick={handleClick}>Resume</Link>
                <Link to="/contact" id="contact" className={location.pathname === "/contact" ? "nav-active notel" : "notel"} onClick={handleClick}>Contact</Link>
                <span id="hamburger" onClick={() => biggerNav()}></span>
                <span id="cross" onClick={() => biggerNav()}></span>
            </div>
            <div id="scrolled">
                <Link to="/" className={location.pathname === "/" ? "nav-active" : ""} onClick={handleClick}>Home</Link>
                <Link to="/studies" className={location.pathname === "/studies" ? "nav-active" : ""} onClick={handleClick}>Studies</Link>
                <Link to="/resume" className={location.pathname === "/resume" ? "nav-active" : ""} onClick={handleClick}>Resume</Link>
                <Link to="/contact" className={location.pathname === "/contact" ? "nav-active" : ""} onClick={handleClick}>Contact</Link>
            </div>
        </div>
    );
}

export default Navbar;