import './Studies.css';
import '../../App.css';
import esilv from '../../images/esilv.jpg';
import pch from '../../images/pch.jpg';

function Studies() {
  return (
    <div className="page">
      <h1>My studies</h1>
      <div className='studies-ctn'>
        <div className='esilv'>
          <div className='text'>
            <h2>ESILV</h2>
            <img src={esilv} alt="esilv"></img>
            <p>ESILV trains generalist engineers with a strong digital dimension. The training includes an international outlook and a project pedagogy. Located at the heart of the La Défense business district, students also actively participate in the growth of associations. Studying at ESILV means being an actor in your own training in the midst of companies that are heavily involved in the school's teaching methods. The school, as well as its international network and its network of partner companies, are present to accompany each student in each of her or his choices and in the high points of her or his engineering training.</p>
            <a href='https://esilv.fr' target="_blank" rel='noreferrer'>Website</a>
          </div>
        </div>
        <div className='pch'>
          <div className='text'>
            <h2>PCH</h2>
            <img src={pch} alt="paul_claudel_d-hulst"></img>
            <p>Paul-Claudel-d'Hulst is a private Catholic school associated by contract with the Government. It is more and more digitally oriented and helps students achieve their goals with the help of a competent teaching staff.</p>
            <a href='https://paulclaudel-hulst.fr/' target="_blank" rel='noreferrer'>Website</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Studies;